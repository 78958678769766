import React, { useCallback, useRef, useState, useEffect } from "react";
import { useViewportScroll, motion, useTransform } from "framer-motion";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import useEventListener from "./hooks/useEventListener";
import useTimeout from "./hooks/useTimeout";

type ParallaxImageProps = {
  alt: string;
  image: IGatsbyImageData;
  yOffset: number;
};

const ParallaxImage = ({ yOffset, alt, image }: ParallaxImageProps) => {
  const easing: any = "easeInOut";

  const { scrollY } = useViewportScroll();

  const imageRef = useRef<HTMLDivElement>(null);
  const [elementTop, setElementTop] = useState(0);

  const setValues = useCallback(() => {
    const node = imageRef?.current;
    if (node) {
      const elTop = node.offsetTop;
      setElementTop(elTop || 0);
    }
  }, [imageRef]);

  useEffect(() => {
    setValues();
  }, []);

  useTimeout(setValues, 400);

  useEventListener("resize", setValues);

  const yRange = [elementTop + 900, elementTop - 500];

  const y = useTransform(scrollY, yRange, [0, -yOffset], easing);

  return (
    <motion.div className="image" ref={imageRef} style={{ y: y }}>
      <GatsbyImage alt={alt} image={image} className="project-image" />
    </motion.div>
  );
};

export default ParallaxImage;

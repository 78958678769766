import React from "react";
import styled from "styled-components";
import { IGatsbyImageData } from "gatsby-plugin-image";
import ProjectCardSmall from "./ProjectCardSmall";

const NextProjectStyles = styled.section`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 0;
  padding: 10rem 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .text-top {
    grid-column: 1 / span 12;
    margin-bottom: 3rem;
    text-align: center;
    display: flex;
    justify-content: center;
    @media ${(props) => props.theme.breakpoints.m} {
      margin-bottom: 1rem;
      grid-column: 1 / span 6;
    }
    div {
      width: 30rem;
    }
  }
`;

type ImageType = {
  asset: {
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
};

type NextProjectTypes = {
  nextProject: {
    title: string;
    slug: {
      current: string;
    };
    tags: string[];
    excerpt: string;
    mainImage: ImageType;
    smallCardImage: ImageType;

    briefSection: {
      title: string;
      description: string;
      briefImage: ImageType;
    };
    solutionSection: {
      title: string;
      description: string;
      solutionImage: ImageType;
    };
    websiteSection: {
      title: string;
      description: string;
      websiteImage: ImageType;
    };
  };
};

const NextProject = ({ nextProject }: NextProjectTypes) => {
  const title = nextProject.title;
  const tags = nextProject.tags;
  const slug = nextProject.slug.current;
  const image =
    nextProject.smallCardImage.asset.localFile.childImageSharp.gatsbyImageData;
  const excerpt = nextProject.excerpt;

  return (
    <NextProjectStyles>
      <div className="text-top">
        <h1>Next Project</h1>
      </div>
      <ProjectCardSmall
        title={title}
        tags={tags}
        slug={slug}
        image={image}
        excerpt={excerpt}
        center
      />
    </NextProjectStyles>
  );
};

export default NextProject;

import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

const ScrollDownStyles = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  right: 2em;
  bottom: 2em;
  @media ${(props) => props.theme.breakpoints.m} {
    right: 0.5em;
    bottom: 0.5em;
  }
  svg {
    fill: transparent;
    path {
      stroke: black;
      stroke-width: 2.4px;
    }
  }
`;

const ScrollDown = () => {
  return (
    <ScrollDownStyles>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <motion.path
          d="M15.1,4.4C7.5,8.1,2.2,15.9,2.2,25c0,12.6,10.2,22.8,22.8,22.8S47.8,37.6,47.8,25S37.6,2.2,25,2.2v31"
          style={{ pathLength: 0 }}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: [0, 1, 1, 0] }}
          transition={{
            repeatDelay: 1,
            repeat: Infinity,
            duration: 4,
          }}
        />
        <motion.path
          style={{ pathLength: 0 }}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: [0, 1, 1, 0] }}
          transition={{
            repeat: Infinity,
            repeatDelay: 4,
            duration: 1,
            delay: 1.5,
          }}
          d="M25 33.6, L35 26.2"
        />
        <motion.path
          style={{ pathLength: 0 }}
          initial={{ pathLength: 0 }}
          animate={{ pathLength: [0, 1, 1, 0] }}
          transition={{
            repeat: Infinity,
            repeatDelay: 4,
            duration: 1,
            delay: 1.5,
          }}
          d="M25 33.6, L15 26.2"
        />
      </svg>
    </ScrollDownStyles>
  );
};

export default ScrollDown;

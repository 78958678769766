import React from "react";
import styled from "styled-components";
import { IGatsbyImageData } from "gatsby-plugin-image";
import ParallaxImage from "./ParallaxImage";
import ScrollDown from "./ScrollDown";

const ProjectTopBannerStyles = styled.div`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 4rem 0 0 0;

  grid-template-rows: auto auto 1fr 1fr 1fr;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 2rem 0 0 0;
    padding: 4rem 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 2rem 0 0 0;
  }

  h1 {
    grid-column: 1 / span 12;
    margin: 0;
    grid-row: 1 / span 2;
    font-size: var(--font-hero-size);
    text-align: center;
    @media ${(props) => props.theme.breakpoints.m} {
      font-size: var(--font-title2-size);
      grid-column: 1 / span 6;
    }
  }

  .image {
    grid-column: 4 / span 6;
    grid-row: 2 / span 4;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 4;
    }
    div {
      height: 100%;
    }
  }

  .banner-background {
    grid-column: 1 / span 12;
    grid-row: 2 / span 3;
    background: var(--color-gray-150);
    z-index: -1;
    position: relative;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
    }
  }
`;

type ProjectTopBannerProps = {
  title: string;
  bannerImage: IGatsbyImageData;
};

const ProjectTopBanner = ({ title, bannerImage }: ProjectTopBannerProps) => {
  return (
    <ProjectTopBannerStyles>
      <h1>{title}</h1>
      <ParallaxImage alt={title} image={bannerImage} yOffset={-100} />
      <div className="banner-background">
        <ScrollDown />
      </div>
    </ProjectTopBannerStyles>
  );
};

export default ProjectTopBanner;

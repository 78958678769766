import React from "react";
import styled from "styled-components";
import { IGatsbyImageData } from "gatsby-plugin-image";
import ParallaxImage from "./ParallaxImage";

const BriefSectionStyles = styled.section`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, var(--column-width))) 1fr;
  gap: 0 2rem;
  margin: 0;
  grid-template-rows: auto auto 1fr;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .brief-image {
    grid-column: 4 / span 11;
    grid-row: 1 / span 2;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 7;
    }
    .image {
      display: flex;
      justify-content: flex-end;
      .project-image {
        @media ${(props) => props.theme.breakpoints.m} {
          transform: translateY(-11rem);
        }
      }
    }
  }

  .brief-background {
    background: var(--color-black);
    grid-row: 2 / span 2;
    grid-column: 1 / span 14;
    z-index: -1;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 8;
    }
  }

  .text-below {
    grid-row: 3 / span 1;
    grid-column: 2 / span 5;
    margin-bottom: 24em;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
      margin-bottom: 10em;
    }
    p,
    h1 {
      color: var(--color-white);
      ::-moz-selection {
        /* Code for Firefox */
        color: var(--color-black);
        background-color: var(--color-white);
      }

      ::selection {
        color: var(--color-black-rgb);
        background-color: var(--color-white);
      }
    }
    h1 {
      font-size: var(--font-title2-size);
      line-height: var(--font-title2-line-height);
    }
  }
`;

type BriefSectionProps = {
  title: string;
  description: string;
  imageAlt: string;
  image: IGatsbyImageData;
};

const BriefSection = ({
  title,
  description,
  image,
  imageAlt,
}: BriefSectionProps) => {
  return (
    <BriefSectionStyles>
      <div className="brief-image">
        <ParallaxImage image={image} alt={imageAlt} yOffset={-300} />
      </div>
      <div className="text-below">
        <p className="highlight-title mb-4">{title}</p>
        <h1>{description}</h1>
      </div>
      <div className="brief-background" />
    </BriefSectionStyles>
  );
};

export default BriefSection;

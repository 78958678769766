import React from "react";
import styled from "styled-components";

const SolutionSectionStyles = styled.section`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, var(--column-width)));
  gap: 0 2rem;
  margin: 0;
  padding: 24em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
    padding: 10em 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .solution-text {
    grid-column-end: span 5;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column-end: span 6;
    }
    h1 {
      font-size: var(--font-title2-size);
      line-height: var(--font-title2-line-height);
    }
  }
`;

type SolutionSectionProps = {
  title: string;
  description: string;
};

const SolutionSection = ({ title, description }: SolutionSectionProps) => {
  return (
    <SolutionSectionStyles>
      <div className="solution-text">
        <p className="highlight-title mb-4">{title}</p>
        <h1>{description}</h1>
      </div>
    </SolutionSectionStyles>
  );
};

export default SolutionSection;

import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { useIntersection } from "react-use";

const TheWebsiteSectionStyles = styled.section`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, var(--column-width))) 1fr;
  gap: 0 2rem;
  margin: 0;

  padding: 10rem 0;
  background: var(--color-black);
  align-items: flex-start;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .website-text {
    grid-column: 2 / span 5;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
    }
    p,
    h1 {
      color: var(--color-white);
      ::-moz-selection {
        /* Code for Firefox */
        color: var(--color-black);
        background-color: var(--color-white);
      }

      ::selection {
        color: var(--color-black-rgb);
        background-color: var(--color-white);
      }
    }
    h1 {
      font-size: var(--font-title4-size);
      line-height: var(--font-title4-line-height);
    }
    &.sticky {
      position: sticky;
      top: 50%;
      @media ${(props) => props.theme.breakpoints.m} {
        position: relative;
        top: 0;
      }
    }
  }

  .image {
    grid-column: 8 / span 6;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
      margin-top: 4em;
    }
  }
`;

type WebsiteSectionProps = {
  title: string;
  description: string;
  imageAlt: string;
  image: IGatsbyImageData;
};

const TheWebsiteSection = ({
  title,
  description,
  imageAlt,
  image,
}: WebsiteSectionProps) => {
  const [inView, setInView] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);

  const intersection = useIntersection(imageRef, {
    root: null,
    threshold: 0,
  });

  useEffect(() => {
    if (intersection) {
      setInView(intersection.isIntersecting);
    }
  });

  return (
    <TheWebsiteSectionStyles>
      <div className={`website-text ${inView ? "sticky" : ""}`}>
        <p className="highlight-title mb-4">{title}</p>
        <h1>{description}</h1>
      </div>
      <div className="image" ref={imageRef}>
        <GatsbyImage alt={imageAlt} image={image} />
      </div>
    </TheWebsiteSectionStyles>
  );
};

export default TheWebsiteSection;

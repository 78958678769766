import React from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styled from "styled-components";
import ProjectTopBanner from "../components/ProjectTopBanner";
import BriefSection from "../components/BriefSection";
import SolutionSection from "../components/SolutionSection";
import TheWebsiteSection from "../components/TheWebsiteSection";
import NextProject from "../components/NextProject";

export type ProjectTypes = {
  title: string;
  slug: {
    current: string;
  };
  tags: string[];
  excerpt: string;
  mainImage: ImageType;
  smallCardImage: ImageType;
  briefSection: {
    title: string;
    description: string;
    briefImageAlt: string;
    briefImage: ImageType;
  };
  solutionSection: {
    title: string;
    description: string;
    solutionImageAlt: string;
    solutionImage: ImageType;
  };
  websiteSection: {
    title: string;
    description: string;
    websiteImageAlt: string;
    websiteImage: ImageType;
  };
};

type ImageType = {
  asset: {
    localFile: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
};

type ProjectDataTypes = {
  pageContext: {
    mainProject: ProjectTypes;
    nextProject: ProjectTypes;
  };
};

const ProjectTemplateStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, var(--column-width))) 1fr;
  gap: 0 2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }
`;

const ProjectTemplate = ({ pageContext }: ProjectDataTypes) => {
  const { mainProject, nextProject } = pageContext;

  return (
    <Layout>
      <Seo title={pageContext.mainProject.title} />

      <ProjectTemplateStyles>
        <ProjectTopBanner
          title={mainProject.title}
          bannerImage={
            mainProject.mainImage.asset.localFile.childImageSharp
              .gatsbyImageData
          }
        />
        <BriefSection
          image={
            mainProject.briefSection.briefImage.asset.localFile.childImageSharp
              .gatsbyImageData
          }
          imageAlt={mainProject.briefSection.briefImageAlt}
          title={mainProject.briefSection.title}
          description={mainProject.briefSection.description}
        />
        <SolutionSection
          title={mainProject.solutionSection.title}
          description={mainProject.solutionSection.description}
        />
        <TheWebsiteSection
          image={
            mainProject.websiteSection.websiteImage.asset.localFile
              .childImageSharp.gatsbyImageData
          }
          imageAlt={mainProject.websiteSection.websiteImageAlt}
          title={mainProject.websiteSection.title}
          description={mainProject.websiteSection.description}
        />
        <NextProject nextProject={nextProject} />
      </ProjectTemplateStyles>
    </Layout>
  );
};

export default ProjectTemplate;
